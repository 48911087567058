<template>
  <div class="home">
    <div class="header_top">
      <handleback></handleback>
      <div>站内信</div>
      <div>玩法说明</div>
    </div>
    <div class="head center">
      <div class="center" @click="handleoperation(1)">全部已读</div>
      <div class="center" @click="handleoperation(2)">全部删除</div>
    </div>
    <div class="main">
      <div
        @click="handleopenmessage(item.id)"
        v-for="(item,index) in messageList"
        :key="index"
        class="main_top space_between"
      >
        <div>
          <div v-if="item.status==='1'">
            <svg
              t="1707123532951"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="3582"
            >
              <path
                d="M931.2 281.6c-9.6-9.6-22.4-9.6-35.2-6.4V224c0-54.4-41.6-96-96-96H224C169.6 128 128 169.6 128 224v54.4c-12.8-6.4-25.6-3.2-35.2 6.4-19.2 16-28.8 41.6-28.8 67.2v448c0 54.4 41.6 96 96 96h704c54.4 0 96-41.6 96-96V352c0-25.6-9.6-51.2-28.8-70.4zM441.6 678.4c38.4 38.4 99.2 38.4 134.4 0l57.6-57.6 217.6 208H172.8l211.2-211.2 57.6 60.8z m240-102.4l214.4-214.4v422.4L681.6 576zM224 192h576c19.2 0 32 12.8 32 32v112l-297.6 297.6c-12.8 12.8-32 12.8-44.8 0L384 531.2l-192-192V224c0-19.2 12.8-32 32-32z m115.2 384L128 787.2V364.8l211.2 211.2z"
                fill="#727477"
                p-id="3583"
              />
            </svg>
          </div>
          <div v-if="item.status==='0'">
            <svg
              t="1707123406935"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="3230"
            >
              <path
                d="M890.256587 198.330044c-9.406616-4.568039-21.888393-7.721869-38.638555-7.721869L170.276942 190.608176c-16.749139 0-29.229893 3.154853-38.637532 7.721869-38.692793 15.714913-66.18197 54.079686-66.18197 98.996688l0 426.930509c0 44.617174 27.080861 82.818217 65.365338 98.724489 9.326795 4.675486 21.970261 7.995091 39.454164 7.995091l681.34109 0c17.484926 0 30.127369-3.318582 39.454164-7.995091 38.25787-15.906272 65.367385-54.107315 65.367385-98.724489L956.43958 297.325709C956.43958 252.40973 928.948356 214.044958 890.256587 198.330044zM904.015502 297.325709l0 426.930509c0 6.90527-1.38766 13.486152-3.752618 19.549242-3.371932-3.997034-7.206418-8.074909-11.936333-12.290931L670.393311 537.327852l225.03016-243.454778c2.745643-2.963495 4.920258-5.819542 7.095896-8.673543C903.445497 289.088094 904.015502 293.139364 904.015502 297.325709zM851.618031 243.980757c4.81383 0 9.381032 0.870834 13.813153 2.121314-1.277138 2.582825-3.669726 6.360871-8.156085 11.174504L545.751556 594.316797c-18.652566 20.174482-50.95455 20.174482-69.60814 0L164.62192 257.276574c-4.486358-4.813632-6.878946-8.591679-8.157108-11.174504 4.432121-1.251503 9.000347-2.121314 13.813153-2.121314L851.618031 243.980757zM121.633112 743.804437c-2.365981-6.063089-3.752618-12.642948-3.752618-19.549242L117.880494 297.325709c0-4.186346 0.571028-8.238638 1.468505-12.126178 2.201222 2.855024 4.350253 5.710049 7.123526 8.673543l225.029136 243.454778-217.932217 194.186677C128.838506 735.730551 125.005044 739.807403 121.633112 743.804437zM170.276942 777.60117c-2.556324 0-5.029756-0.379647-7.477605-0.760317 1.413244-1.52268 3.099721-3.235694 5.274336-5.194302l219.374115-195.436134 50.547257 54.677297c19.333093 20.909216 45.244267 32.43676 72.952441 32.43676 27.707151 0 53.619348-11.528568 72.952441-32.43676l50.548281-54.677297 219.346484 195.436134c2.201222 1.958608 3.8877 3.671623 5.301967 5.194302-2.473432 0.38067-4.920258 0.760317-7.478628 0.760317L170.276942 777.60117z"
                fill="#3a97e4"
                p-id="3231"
              />
            </svg>
          </div>
        </div>
        <div class="main_top_center textover-f">{{ item.message }}</div>
        <div>{{ item.sendTime }}</div>
      </div>
    </div>
    <div class="out" v-if="onView">
      <div class="out_main animate__animated" ref="viewel">
        <div class="out_main_close" @click="handleClose">
          <svg
            t="1707125075609"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="3781"
          >
            <path
              d="M898.844444 830.577778c22.755556 17.066667 22.755556 51.2 0 73.955555s-51.2 22.755556-73.955555 0L512 591.644444l-312.888889 312.888889c-11.377778 11.377778-22.755556 17.066667-39.822222 17.066667-11.377778 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-22.755556-51.2 0-73.955555l312.888889-312.888889-307.2-318.577778c-11.377778-5.688889-17.066667-22.755556-17.066667-34.133333 0-11.377778 5.688889-28.444444 17.066667-34.133334 22.755556-22.755556 51.2-22.755556 73.955555 0L512 443.733333l312.888889-312.888889c22.755556-22.755556 51.2-22.755556 73.955555 0s22.755556 51.2 0 73.955556l-312.888888 312.888889 312.888888 312.888889z"
              fill="#727477"
              p-id="3782"
            />
          </svg>
        </div>
        <div v-if="Object.keys(viewList).length === 0" class="out_main_ref center">
          <i class="el-icon-refresh-right"></i>
        </div>
        <div v-else>
          <div class="out_main_title">站内信</div>
          <div class="out_main_time">{{ viewList.sendTime }}</div>
          <div class="out_main_content">{{ viewList.message }}</div>
        </div>
      </div>
    </div>
    <div class="out" v-if="onOperation">
      <div class="column owa">
        <div class="owa_i">温馨提示</div>
        <div>确定进行操作吗</div>
        <div class="space_between owa_iii">
          <div class="btn_c center btn_click" @click="onOperation=false">取消</div>
          <div class="btn_q center btn_click" @click="handlesubmit">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMessageList,
  getMessageView,
  getMessageOperation
} from "@/api/index";
export default {
  data() {
    return {
      loading: true,
      messageList: [],
      onView: false,
      onOperation: false,
      viewList: {}
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    handlesubmit() {
      let status = localStorage.getItem("status");
      let arr = this.messageList.map(item => item.id);
      let obj = {
        ids: arr,
        status: status
      };
      getMessageOperation(obj).then(res => {
        this.getlist();
        if(res.data.code){
              console.log("成功")
             }
        this.onOperation = false;
      });
    },
    handleoperation(res) {
      localStorage.setItem("status", res);
      this.onOperation = true;
    },
    handleClose() {
      const element = this.$refs.viewel;
      element.classList += " animate__fadeOut";
      setTimeout(() => {
        this.onView = false;
        this.viewList = {};
      }, 500);
    },
    handleopenmessage(res) {
      this.onView = true;

      getMessageView(res).then(res => {
    
        setTimeout(() => {
          this.viewList = res.data.data;
        }, 500);
      });
    },
    getlist() {
      getMessageList().then(res => {
        this.messageList = res.data.rows;
      
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  margin-top: 14px;
  .main_top {
    width: 100%;
    margin-top: 4px;
    height: 49px;
    background: #101115;
    border-radius: 7px 7px 7px 7px;
    font-size: 17px;
    padding: 0 17px 0 23px;
    .icon {
      width: 24px;
      height: 24px;
    }
    .main_top_center {
      width: 40%;
    }
  }
}
.home {
  width: 95%;
  margin: 0 auto;
  .header_top {
    width: 100%;
    height: 56px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div:first-child {
    }
    div:last-child {
      color: #fff;
      font-size: 12px;
      text-decoration: underline;
      line-height: 1.5;
      opacity: 0;
    }
  }
}
.out {
  width: 525px;
  height: 100vh;
  transform: translateX(-50%);
  position: fixed;
  left: 50%;
  top: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 2;
  text-align: left;
  .out_main {
    width: 95%;
    margin: 0 auto;
    position: relative;
    height: 420px;
    background: #101115;
    border-radius: 14px 14px 14px 14px;
    margin-top: 211px;
    padding: 27px;
    overflow: hidden;
    justify-content: space-around;
    .out_main_close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: grab;
      .icon {
        width: 25px;
        height: 25px;
      }
    }
    .out_main_ref {
      width: 100%;
      height: 100%;
      font-size: 25px;
      animation: xz1 2s infinite linear;
    }
    @keyframes xz1 {
      0% {
        transform: rotate(0);
      }

      100% {
        transform: rotate(720deg);
      }
    }
    .out_main_title {
      width: 100%;
      font-size: 18px;
      text-align: center;
    }
    .out_main_time {
      width: 100%;
      margin: 14px 0 0 0;
      font-size: 18px;
      text-align: right;
    }
    .out_main_content {
      margin: 14px 0 0 0;
      text-align: center;
    }
  }
  .owa {
    width: 95%;
    height: 245px;
    margin: 0 auto;
    margin-top: 210px;
    background: #101115;
    border-radius: 21px;
    overflow: hidden;
    opacity: 1;
    justify-content: space-between;
    padding: 35px;
    position: relative;
    .owa_i {
      font-size: 28px;
      font-weight: 500;
      color: #ffffff;
    }
    .owa_iii {
      width: 95%;
      margin: 0 auto;
      font-size: 21px;
      position: relative;
      .btn_c {
        width: 47%;
        height: 42px;
        background: linear-gradient(
          360deg,
          rgba(114, 116, 119, 0.5) 0%,
          rgba(114, 116, 119, 0.25) 100%
        );
        opacity: 1;
        border: 1px solid #727477;
      }
      .btn_q {
        width: 47%;
        height: 42px;
        background: linear-gradient(
          360deg,
          rgba(37, 244, 132, 0.5) 0%,
          rgba(37, 244, 132, 0.25) 100%
        );
        opacity: 1;
        border: 1px solid #25f484;
      }
    }
  }
  .owa::before {
    content: ""; /*必须设置content属性*/
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      -204deg,
      rgba(37, 244, 132, 0.2) 0%,
      rgba(32, 29, 48, 0) 40%,
      rgba(248, 125, 81, 0) 100%
    );
    position: absolute;
    left: 0;
    top: 0;
  }
}
.head {
  justify-content: end;
  div {
    margin-left: 10px;
    width: 98px;
    height: 35px;
    border-radius: 35px 35px 35px 35px;
    opacity: 1;
    border: 2px solid #727477;
    font-size: 18px;
    font-weight: bold;
    color: #727477;
  }
}
</style>